<template>
  <div class="content">
    <div>
      <van-nav-bar :title="$t('other.translate50')" left-arrow @click-left="onClickLeft" />
      <div class="tops">
        <div class="banner">
          <div class="bannerText">{{ $t('other.translate38') }}</div>
          <div class="bannerNumber">{{ contractAssetPNL.dynamicProfit }}</div>
          <div class="bannerCan" style="margin-top: 24px;">{{ $t('other.translate7') }} USDT: <span
              style="color: #000">{{
                contractAssetPNL.balance.toFixed(2) || 0 }}</span></div>
          <div class="bannerCan" style="margin-top: 8px;">{{ $t('other.translate39') }} USDT: <span
              style="color: #000">{{
                contractAssetPNL.frozon.toFixed(2) || 0 }}</span></div>
        </div>
        <div class="bannerBox">
          <div class="bannerBoxContent">
            <img src="../../assets/img/tradeImg2.png" alt="" style="width: 44px;height: 44px;margin-right: 5px;">
            <div>
              <div class="float" :lang="$i18n.locale">{{ $t('other.translate40') }}</div>
              <div class="floatN">{{ contractAssetPNL.floatPnL.toFixed(2) || 0 }}</div>
            </div>
          </div>
          <div class="bannerBoxContent">
            <img src="../../assets/img/tradeImg3.png" alt="" style="width: 44px;height: 44px;margin-right: 5px;">
            <div>
              <div class="float" :lang="$i18n.locale">{{ $t('other.translate41') }}</div>
              <div class="floatN"> {{ contractAssetPNL.riskRate.toFixed(2) || 0 }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="tag">
        <span class="tagText" :class="{ 'active': chooseNum == index }" v-for="item, index in tabsList"
          @click="choose(index)">{{ item.title }}</span>
      </div>
      <div style="width: 100%;height: 1px;background-color: #0000001A;margin: 8px 0 16px 0;"></div>
      <div class="tabsBox" v-if="chooseNum == 0">
        <!-- <div class="time">
          <div class="one" @click="clickStartTime(0)">
            <div class="startTime">{{ startTime }}</div>
            <div class="fdyk">{{ $t('other.translate42') }}</div>
          </div>
          <div class="imgs">
            <img src="../../assets/img/tradeImg4.png" alt="" />
          </div>
          <div class="one" @click="clickStartTime(1)">
            <div class="startTime">{{ endTime }}</div>
            <div class="fdyk">{{ $t('other.translate43') }}</div>
          </div>
        </div> -->
        <div class="tradeHistory">
          <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
            :description="$t('other.translate58')" v-if="holdOrder.rows.length == 0" />
          <!-- <ul class="assetsList" v-else>
            <li v-for="(item, idx) of holdOrder.rows" :key="idx" class="li">
              <div class="forTitle">
                <div class="type">
                  {{ item.direction == "BUY" ? "做多" : "做空" }}
                </div>
                {{ item.symbol }} X {{ item.leverage }}
              </div>
              <div class="forTitle">
                {{ formatTimestamp(item.time) }}
              </div>
              <div class="boxOne1">
                <div class="">订单号:{{ item.orderId }}</div>
              </div>
              <div class="boxOne">
                <div class="">订单类型</div>
                <div class="">{{ followTypeFormat(item) }}</div>
              </div>
              <div class="boxOne">
                <div class="">持仓价</div>
                <div class="">{{ item.price }}</div>
              </div>
              <div class="boxOne">
                <div class="">最新价格</div>
                <div class="">{{ item.currentPrice }}</div>
              </div>
              <div class="boxOne">
                <div class="">成交量</div>
                <div class="">{{ item.amount }}</div>
              </div>
              <div class="boxOne">
                <div class="">保证金</div>
                <div class="">{{ item.marginMoney }}</div>
              </div>
              <div class="boxOne">
                <div class="">杠杆倍数</div>
                <div class="">{{ item.leverage }}</div>
              </div>
              <div class="boxOne">
                <div class="">止盈价</div>
                <div class="">{{ item.stopProfitPrice }}</div>
              </div>
              <div class="boxOne">
                <div class="">支付方式</div>
                <div class="">{{ "资金" }}</div>
              </div>
              <div class="boxOne">
                <div class="">止损价</div>
                <div class="">{{ item.stopLossPrice }}</div>
              </div>
              <div class="boxOne1">
                <div class="numALl" :class="item.direction == 'BUY' ? 'redBg' : 'greenBg'">
                  {{
                    item.direction == "BUY"
                      ? "-" + (item.price - item.currentPrice).toFixed(2)
                      : (item.price - item.currentPrice).toFixed(2)
                  }}
                </div>
              </div>
              <div class="boxOne">
                <div class="dis">
                  <van-button size="large" type="info" class="xgButton" plain round hairline
                    @click="close(item)">平仓</van-button>
                  <van-button size="large" type="info" class="xgButton" plain round hairline
                    @click="doModifyStop(item, tskb)">修改</van-button>
                </div>
              </div>
            </li>
          </ul> -->
          <ul class="assetsList" v-else>
            <li v-for="(item, idx) of holdOrder.rows" :key="idx" class="li">
              <div class="forTitle">
                <span>{{ item.symbol }}</span>
                <div class="forTitleBox">X{{ item.leverage }}</div>
                <div class="type" :style="{ backgroundColor: item.direction == 'BUY' ? '#3AD29F' : '#EB4335' }">
                  {{ item.direction == "BUY" ? $t('other.translate9') : $t('other.translate10') }}
                </div>
              </div>
              <div class="times">
                {{ formatTimestamp(item.time) }}
              </div>
              <div class="order">{{ $t('other.translate45') }}:{{ item.orderId }}</div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate32') }}</div>
                <div class="">{{ followTypeFormat(item) }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate24') }}</div>
                <div class="">{{ item.price }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate27') }}</div>
                <div class="">{{ item.currentPrice }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate26') }}</div>
                <div class="">{{ item.amount }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate30') }}</div>
                <div class="">{{ item.marginMoney }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate46') }}</div>
                <div class="">{{ item.leverage }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate25') }}</div>
                <div class="">{{ item.stopProfitPrice }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate29') }}</div>
                <div class="">{{ $t('other.translate79') }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate28') }}</div>
                <div class="">{{ item.stopLossPrice }}</div>
              </div>
              <div class="boxOne1">
                <div class="numALl" :class=" item.profitLost >= 0 ? 'greenBg' : 'redBg'">
                  {{
                   item.profitLost >= 0
                      ? item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                      : "-" + item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                  }}
                </div>
              </div>
              <div class="boxOne">
                <div class="dis" :lang="$i18n.locale">
                  <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain
                    @click="close(item)">{{
                      $t('other.translate33') }}</van-button>
                  <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain
                    @click="doModifyStop(item, tskb)">{{
                      $t('other.translate34') }}</van-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <moreData :total="paramsIsaacOne.total" :pageNum="paramsIsaacOne.pageNo" :pageSize="paramsIsaacOne.pageSize"
          @clickMoreDataS="clickMoreDataOne" />
      </div>
      <div class="tabsBox" v-if="chooseNum == 1">
        <!-- <div class="time">
          <div class="one" @click="clickStartTime(0)">
            <div class="startTime">{{ startTime }}</div>
            <div class="fdyk">{{ $t('other.translate42') }}</div>
          </div>
          <div class="imgs">
            <img src="../../assets/img/tradeImg4.png" alt="" />
          </div>
          <div class="one" @click="clickStartTime(1)">
            <div class="startTime">{{ endTime }}</div>
            <div class="fdyk">{{ $t('other.translate43') }}</div>
          </div>
        </div> -->
        <div class="tradeHistory">
          <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
            :description="$t('other.translate58')" v-if="currentOrder.rows.length == 0" />
          <ul class="assetsList" v-else>
            <li v-for="(item, idx) of currentOrder.rows" :key="idx" class="li">
              <div class="forTitle">
                <span>{{ item.symbol }}</span>
                <div class="forTitleBox">X{{ item.leverage }}</div>
                <div class="type" :style="{ backgroundColor: item.direction == 'BUY' ? '#3AD29F' : '#EB4335' }">
                  {{ item.direction == "BUY" ? $t('other.translate9') : $t('other.translate10') }}
                </div>
              </div>
              <div class="times">
                {{ formatTimestamp(item.time) }}
              </div>
              <div class="order">{{ $t('other.translate45') }}:{{ item.orderId }}</div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate32') }}</div>
                <div class="">{{ followTypeFormat(item) }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate24') }}</div>
                <div class="">{{ item.price }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate27') }}</div>
                <div class="">{{ item.currentPrice }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate26') }}</div>
                <div class="">{{ item.amount }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate30') }}</div>
                <div class="">{{ item.marginMoney }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate46') }}</div>
                <div class="">{{ item.leverage }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate25') }}</div>
                <div class="">{{ item.stopProfitPrice }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate29') }}</div>
                <div class="">{{ $t('other.translate79') }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate28') }}</div>
                <div class="">{{ item.stopLossPrice }}</div>
              </div>
              <div class="boxOne1">
                <div class="numALl" :class=" item.profitLost >= 0 ? 'greenBg' : 'redBg'">
                  {{
                   item.profitLost >= 0
                      ? item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                      : "-" + item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                  }}
                </div>
              </div>
              <div class="boxOne">
                <div class="dis" :lang="$i18n.locale">
                  <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain
                    @click="close(item)">{{
                      $t('other.translate33') }}</van-button>
                  <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain
                    @click="doModifyStop(item, tskb)">{{
                      $t('other.translate34') }}</van-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <moreData :total="paramsIsaacOne.total" :pageNum="paramsIsaacOne.pageNo" :pageSize="paramsIsaacOne.pageSize"
          @clickMoreDataS="clickMoreDataTow" />
      </div>
      <div class="tabsBox" v-if="chooseNum == 2">
        <!-- <div class="time">
          <div class="one" @click="clickStartTime(0)">
            <div class="startTime">{{ startTime }}</div>
            <div class="fdyk">{{ $t('other.translate42') }}</div>
          </div>
          <div class="imgs">
            <img src="../../assets/img/tradeImg4.png" alt="" />
          </div>
          <div class="one" @click="clickStartTime(1)">
            <div class="startTime">{{ endTime }}</div>
            <div class="fdyk">{{ $t('other.translate43') }}</div>
          </div>
        </div> -->
        <div class="tradeHistory">
          <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
            :description="$t('other.translate58')" v-if="closeOrder.rows.length == 0" />
          <ul class="assetsList" v-else>
            <li v-for="(item, idx) of closeOrder.rows" :key="idx" class="li">
              <div class="forTitle">
                <span>{{ item.symbol }}</span>
                <div class="forTitleBox">X{{ item.leverage }}</div>
                <div class="type" :style="{ backgroundColor: item.direction == 'BUY' ? '#3AD29F' : '#EB4335' }">
                  {{ item.direction == "BUY" ? $t('other.translate9') : $t('other.translate10') }}
                </div>
              </div>
              <div class="times">
                {{ formatTimestamp(item.time) }}
              </div>
              <div class="order">{{ $t('other.translate45') }}:{{ item.orderId }}</div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate32') }}</div>
                <div class="">{{ followTypeFormat(item) }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate24') }}</div>
                <div class="">{{ item.price }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate27') }}</div>
                <div class="">{{ item.currentPrice }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate26') }}</div>
                <div class="">{{ item.amount }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate30') }}</div>
                <div class="">{{ item.marginMoney }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate46') }}</div>
                <div class="">{{ item.leverage }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate25') }}</div>
                <div class="">{{ item.stopProfitPrice }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate29') }}</div>
                <div class="">{{ $t('other.translate79') }}</div>
              </div>
              <div class="boxOne">
                <div class="">{{ $t('other.translate28') }}</div>
                <div class="">{{ item.stopLossPrice }}</div>
              </div>
              <div class="boxOne1">
                <div class="numALl" :class=" item.profitLost >= 0 ? 'greenBg' : 'redBg'">
                  {{
                   item.profitLost >= 0
                      ? item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                      : "-" + item.profitLost.toFixed(coins._map[item.symbol].baseCoinScale)
                  }}
                </div>
              </div>
              <div class="boxOne">
                <div class="dis" :lang="$i18n.locale">
                  <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain
                    @click="close(item)">{{
                      $t('other.translate33') }}</van-button>
                  <van-button size="large" type="info" :lang="$i18n.locale" class="xgButton" plain
                    @click="doModifyStop(item, tskb)">{{
                      $t('other.translate34') }}</van-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <moreData :total="paramsIsaacOne.total" :pageNum="paramsIsaacOne.pageNo" :pageSize="paramsIsaacOne.pageSize"
          @clickMoreDataS="clickMoreDataThree" />
      </div>
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker type="datehour" @confirm="onConfirm" @cancel="showPicker = false" />
      </van-popup>
      <van-dialog v-model="showModifyStop" :title="$t('other.translate13')" :show-cancel-button="false"
        :showConfirmButton="false">
        <van-field v-model="form.stopProfitPrice" :label="$t('other.translate87')" label-width="66px"
          placeholder="0.0" />
        <van-field v-model="form.stopLossPrice" :label="$t('other.translate88')" label-width="66px" placeholder="0.0" />
        <div class="btnBox">
          <div class="btnC" @click="showModifyStop = false">{{ $t('button.otc.cancel') }}</div>
          <div class="btnS" @click="modifyStopProfitLoss">{{ $t('button.otc.sure') }}</div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>
<script>
import moreData from "../../components/moreData/moreData";
export default {
  data() {
    return {
      paramsIsaacOne: {},
      paramsIsaacTow: {},
      paramsIsaacThree: {},
      showModifyStop: false,
      tskb: "tskb",
      form: {
        stopProfitPrice: "",
        stopLossPrice: "",
      },
      order: {},
      current: 0,
      tabsList: [{ title: this.$t('other.translate47') }, { title: this.$t('other.translate48') }, { title: this.$t('other.translate49') }],
      startTime: "",
      endTime: "",
      showPicker: false,
      num: 0,
      holdOrder: {
        rows: [],
      },
      currentOrder: {
        rows: [],
      },
      closeOrder: {
        rows: [],
      },
      contractAssetPNL: {
        dynamicProfit: 0.0,
        balance: 0.0,
        available: 0.0,
        frozon: 0.0,
        floatPnL: 0.0,
        dynamicProfitPercent: 0.0,
        riskRate: 0.0,
      },
      currentCoin: {
        base: "",
        coin: "",
        symbol: "",
        levers: [],
      },
      chooseNum: 0,
      coins: {
        _map: [],
        USDT: []
      },
    };
  },
  components: {
    moreData,
  },
  watch: {
    $route() {
      console.log("222", 222);
    },
  },
  computed: {},
  mounted() {
    this.contractAssetPNL = this.$store.state.contractAssetPNLAll;
    this.currentCoin = this.$store.state.currentCoinAll;
    // this.holdOrder = this.$store.state.holdOrderAll;
    // this.currentOrder = this.$store.state.currentOrderAll;
    // this.closeOrder = this.$store.state.closeOrderAll;
    this.paramsIsaacOne = this.$store.state.paramsIsaacOne;
    this.paramsIsaacTow = this.$store.state.paramsIsaacTow;
    this.paramsIsaacThree = this.$store.state.paramsIsaacThree;
    this.paramsIsaacOne.pageNo = 1;
    this.paramsIsaacTow.pageNo = 1;
    this.paramsIsaacThree.pageNo = 1;
    this.holdOrder.rows = [];
    this.currentOrder.rows = [];
    this.closeOrder.rows = [];

    console.log(" this.paramsIsaacOne", this.paramsIsaacOne);
    console.log(" this.paramsIsaacTow", this.paramsIsaacTow);
    console.log(" this.paramsIsaacThree", this.paramsIsaacThree);

    this.getHoldOrder();
    this.getCurrentOrder();
    this.getCloseOrder();
    this.getSymbol()
  },
  methods: {
    choose(index) {
      this.chooseNum = index
    },
    getSymbol() {
      this.$http.post(this.host + '/market/symbol-thumb', {}).then((response) => {
        let resp = response.body;
        //先清空已有数据
        for (let i = 0; i < resp.length; i++) {
          let coin = resp[i];
          coin.base = resp[i].symbol.split('/')[1];
          this.coins[coin.base] = [];
          this.coins._map = [];
          this.coins.favor = [];
        }
        for (let i = 0; i < resp.length; i++) {
          let coin = resp[i];
          coin.price = resp[i].close = resp[i].close.toFixed(resp[i].baseCoinScale);
          coin.rose = resp[i].chg > 0 ? '+' + (resp[i].chg * 100).toFixed(2) + '%' : (resp[i].chg * 100).toFixed(2) + '%';
          coin.coin = resp[i].symbol.split('/')[0];
          coin.base = resp[i].symbol.split('/')[1];
          coin.href = (coin.coin + '_' + coin.base).toLowerCase();
          coin.isFavor = false;
          coin.isGreen = resp[i].chg > 0 ? true : false;
          this.coins._map[coin.symbol] = coin;
          this.coins[coin.base].push(coin);
          if (coin.symbol == this.currentCoin.symbol) {
            this.currentCoin = coin;
            this.plate.price = coin.price;
            this.form.buy.limitPrice = this.form.sell.limitPrice = coin.price;

            this.getSymbolLeverage(this.currentCoin.symbol);
          }
        }

        this.startWebsock();
      });
    },
    getHoldOrder(pageNo) {
      this.paramsIsaacOne.holdStatus = "HOLD";

      //查询历史委托
      let that = this;
      this.$http
        .post(this.host + this.api.exchange.hold, this.paramsIsaacOne)
        .then((response) => {
          let resp = response.body;

          this.paramsIsaacOne = {
            ...this.paramsIsaacOne,
            total: resp.totalElements,
          };

          if (resp.content != undefined) {
            if (resp.content.length > 0) {
              this.holdOrder.rows.push(...resp.content);
              this.$store.commit("setLodaIsaac", false);
            } else {
              this.holdOrder.rows = [];
            }
          } else {
            this.holdOrder.rows = [];
          }
        });
    },
    getCurrentOrder() {
      //查询当前委托
      let that = this;
      this.$http
        .post(this.host + this.api.exchange.current, this.paramsIsaacTow)
        .then((response) => {
          let resp = response.body;

          this.paramsIsaacTow = {
            ...this.paramsIsaacTow,
            total: resp.totalElements,
          };

          if (resp.content && resp.content.length > 0) {
            this.currentOrder.rows.push(...resp.content);
            this.$store.commit("setLodaIsaac", false);
          } else {
            this.currentOrder.rows = [];
          }
        });
    },
    getCloseOrder(pageNo) {
      let that = this;
      this.$http
        .post(this.host + this.api.exchange.closeList, this.paramsIsaacThree)
        .then((response) => {
          let resp = response.body;

          this.paramsIsaacThree = {
            ...this.paramsIsaacThree,
            total: resp.totalElements,
          };

          if (resp.content != undefined) {
            if (resp.content.length > 0) {
              this.closeOrder.rows.push(...resp.content);
              this.$store.commit("setLodaIsaac", false);
            } else {
              this.closeOrder.rows = [];
            }
          } else {
            this.closeOrder.rows = [];
          }
        });
    },
    clickMoreDataOne() {
      this.paramsIsaacOne.pageNo += 1;
      this.getHoldOrder();
    },
    clickMoreDataTow() {
      this.paramsIsaacTow.pageNo += 1;
      this.getCurrentOrder();
    },
    clickMoreDataThree() {
      this.paramsIsaacThree.pageNo += 1;
      this.getCloseOrder();
    },
    followTypeFormat(row) {
      if (row.followType == 0) {
        return this.$t('other.translate35');
      }
      if (row.followType == 1) {
        if (row.followUserName) {
          return this.$t('other.translate36') + "(" + row.followUserName + ")";
        } else {
          return this.$t('other.translate36');
        }
      }
      if (row.fundsType === "TRIAL") {
        return this.$t('other.translate37');;
      }
      return this.$t('other.translate35');;
    },
    onClickLeft() {
      this.$router.back();
      // this.$mts.goto("otherOrder");

      console.log("111", 111);
      // this.$router.push({
      //   name: "otherOrder",
      // });
    },
    clickStartTime(index) {
      this.showPicker = true;
      this.num = index;
    },
    onConfirm(value) {
      if (this.num == "0") {
        this.startTime = this.formatTimestamp(value);
      } else {
        this.endTime = this.formatTimestamp(value);
      }
      this.showPicker = false;
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp); // 注意时间戳通常是秒为单位，需要乘以1000转为毫秒
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth() 是从0开始的
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
        } ${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`;
    },
    close(item) {
      this.$bus.$emit("funcBus", item);
    },
    doModifyStop(item, val) {
      this.showModifyStop = true;
      this.order = item;
    },
    modifyStopProfitLoss() {
      this.showModifyStop = false;
      this.order.stopProfitPrice = this.form.stopProfitPrice;
      this.order.stopLossPrice = this.form.stopLossPrice;
      this.$bus.$emit("showModifyStop", this.order);
    },
  },
};
</script>
<style scoped>
.btnBox {
  width: 216px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 45px;
}

.btnC {
  height: 36px;
  width: 100px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: #00000080;
  border: 1px solid #00000080;
  border-radius: 4px
}

.btnS {
  height: 36px;
  width: 100px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  background: #0052FF;
  color: #fff;
  border-radius: 4px
}

::v-deep .van-dialog {
  width: 334px;
  height: 282px;
  border-radius: 16px;
}

::v-deep .van-field__label {
  padding-top: 7px !important
}

::v-deep .van-dialog__footer {
  border: none !important;
  margin-top: 69px
}

::v-deep .van-field__control {
  width: 240px !important;
  height: 36px !important;
  border: 1px solid #0000001A !important;
  border-radius: 4px !important;
}

::v-deep .van-dialog .van-dialog__footer .van-button {
  border: none !important;
}

::v-deep .van-dialog__header {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
}

.forTitleBox {
  width: auto;
  min-width: 23px;
  height: 16px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  border-radius: 10px;
  color: #fff;
  padding: 0 5px;
  margin-left: 8px;
  background: linear-gradient(114.88deg, #000000 42.57%, #7C7C7C 94.06%);
}

.order {
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  color: #000;
  margin: 24px 0 8px 0;
  display: flex;
  justify-content: flex-start;
}

.time {
  display: flex;
  justify-content: space-around;
}

.dis:lang(zh-CN) {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.dis:lang(en-US) {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: -18.7226vw
}

.numALl {
  width: 20vw;
  height: 3vh;
  /* background: #16c784; */
  color: #fff;
  text-align: center;
  line-height: 3vh;
  border-radius: 15px;
}

.xgButton:lang(zh-CN) {
  width: 75px;
  height: 30px;
  font-size: 3vw;
  margin-right: 2vw;
  background: #0000001A;
  border-radius: 4px;
  border: none;

}

.xgButton:lang(en-US) {
  width: auto;
  height: 30px;
  font-size: 3vw;
  margin-right: 2vw;
  background: #0000001A;
  border-radius: 4px;
  border: none;
  padding: 0 5px !important;
}

.disPadd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5vw;
}

.redBg {
  background: #FA4940;
  color: #fff;
  text-align: center;
}

.greenBg {
  background: #3AD29F;
  color: #fff;
  text-align: center;
}

.li {
  width: 343px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #0000001A;
  margin-bottom: 8px;
  margin-top: 8px;
}

.times {
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  color: #00000080;
  margin-top: -24px;
  display: flex;
  justify-content: flex-start;
}

.boxOne {
  width: 30%;
  text-align: center;
  margin-bottom: 1vh;
}

.boxOne1 {
  width: 30%;
  text-align: center;
  margin-bottom: 1vh;
}

.boxOne div:first-child {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #00000080
}

.boxOne div:last-child {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #000
}

.forTitle {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 2vh;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;

}

.type {
  width: 80px;
  height: 31px;
  background-color: #000;
  border-radius: 4px;
  margin-right: 4vw;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 31px;
  text-align: center;
  margin-left: 84px
}

.boxOne1 {
  width: 33%;
  text-align: center;
  margin-bottom: 1vh;
}

.one {
  width: 164px;
  height: 28px;
  background: #F6F6F6;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #00000080;

}

.tagText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  color: #00000080;
}

.active {
  color: #000
}

.tag {
  width: 310px;
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.bottom {
  width: 100%;
  height: 64vh;
  overflow-y: auto;
  margin-top: 8px;
  background-color: #fff;
  padding-top: 24px;
}

.floatN {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;

}

.float:lang(zh-CN) {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #00000080;

}

.float:lang(en-US) {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #00000080;
  overflow: hidden;
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.bannerBoxContent {
  width: 164px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #00000080;
  display: flex;
  align-items: center;
}

.bannerBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

}

.bannerCan {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #00000080;

}

.bannerNumber {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
}

.bannerText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #00000080
}

.banner {
  width: 100%;
  height: 160px;
  border: 1px solid #0000001A;
  background: #1F1F1F0D;
  border-radius: 16px;
  margin: 0 auto;
  position: relative;
  padding: 16px;
}

.banner::after {
  content: "";
  width: 117px;
  height: 116px;
  position: absolute;
  background: url("../../assets/img/tradeImg1.png") no-repeat;
  background-size: 100%;
  top: 16px;
  right: 8px;
}

.tops {
  width: 100%;
  height: 252px;
  background-color: #fff;
  padding: 0 16px;
  padding-top: 8px;
}

::v-deep .van-nav-bar__content {
  background: #fff;
}

::v-deep .van-nav-bar__title {
  color: #000 !important;
  font-family: SF Pro;
  font-size: 4.0712vw;
  font-weight: 510;
  background: #fff;
}

::v-deep .van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  background: #fff;
  color: #000 !important;
}

::v-deep .van-nav-bar .van-icon {
  background: #fff;
  color: #000 !important;
}
</style>
